import _merge from 'lodash/merge';
import { CHANGE_CURRENT_FACILITATOR, GET_DASHBOARD_FOR_FACILITATOR, GET_SECONDARY_DASHBOARD_DATA, LOAD_INITIAL_STATE, CHANGE_DASHBOARD_SORT_ORDER, LOAD_FACILITATOR_DISCUSSION_BOARDS } from './actions';

export const SORT_ORDER_TYPES = {
  START_DATE_NEWEST_FIRST: 'start_date_newest_first',
  START_DATE_OLDEST_FIRST: 'start_date_oldest_first',
  CLOSING_DATE_NEWEST_FIRST: 'closing_date_newest_first',
  CLOSING_DATE_OLDEST_FIRST: 'closing_date_oldest_first',
};

const initialState = {
  facilitators: {
    byId: {},
    allIds: [],
  },
  currentFacilitator: undefined,
  loadingFacilitatorData: true,
  subGroups: undefined,
  loadingSubGroups: false,
  sortOrder: SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_STATE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DASHBOARD_FOR_FACILITATOR.SUCCESS:
      return {
        ...state,
        subGroups: action.payload,
        loadingFacilitatorData: false,
      };
    case LOAD_FACILITATOR_DISCUSSION_BOARDS.REQUEST:
      return {
        ...state,
        loadingSubGroups: true,
      };
    case LOAD_FACILITATOR_DISCUSSION_BOARDS.SUCCESS: {
      const facilitator = state.facilitators.byId[action.payload.userId];
      return {
        ...state,
        currentFacilitator: { id: facilitator?.id, firstName: facilitator?.firstName, lastName: facilitator?.lastName },
        subGroups: action.payload.subGroups,
        loadingSubGroups: false,
      };
    }

    case GET_SECONDARY_DASHBOARD_DATA.SUCCESS:
      return {
        ...state,
        subGroups: _merge(
          {},
          state.subGroups,
          action.payload.insufficientParticipations,
          action.payload.extendedParticipants,
          action.payload.flaggedJournals,
        ),
      };
    case CHANGE_CURRENT_FACILITATOR.SYNC: {
      const facilitator = state.facilitators.byId[action.payload.id];

      return {
        ...state,
        currentFacilitator: { id: facilitator.id, firstName: facilitator.firstName, lastName: facilitator.lastName },
        loadingFacilitatorData: true,
      };
    }
    case CHANGE_DASHBOARD_SORT_ORDER.SYNC:
      return {
        ...state,
        sortOrder: action.payload.sortLabel,
      };
    default:
      return state;
  }
};
