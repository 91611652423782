/* eslint-disable import/no-import-module-exports */
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import UserConfirmation from 'components/shared/UserConfirmation';
import rootSaga from 'sagas';
import createReducer from './reducer';
import dispatchInitialActions from './initializers';

export const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => UserConfirmation(message, callback),
});

const configureStore = (initialState, services = {}) => {
  // Set up the middleware
  const sagaMiddleware = createSagaMiddleware();
  const middleware = process.env.NODE_ENV === 'development' ? [createLogger(), sagaMiddleware] : [sagaMiddleware];

  // Create the redux store
  const store = createStore(
    createReducer(history), // root reducer with router state
    initialState,
    composeWithDevToolsDevelopmentOnly(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleware,
      ),
    ),
  );

  // Start the root saga and services
  let sagaTask = sagaMiddleware.run(rootSaga, services);

  // Dispatch the actions that should run any time react is loaded
  dispatchInitialActions(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      store.replaceReducer(createReducer(history));
    });
    module.hot.accept('sagas', () => {
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(rootSaga, services);
      });
    });
  }

  return store;
};

export default configureStore;
