import { fetchGet, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import { CREATE_VOUCHERS, RESET_VOUCHERS, GET_FORM_DATA, GET_VOUCHERS, GET_VOUCHER_PAGES, SORT_VOUCHERS } from 'store/vouchers/actions';

// TODO: @koda maybe look into converting theses values to one-to-one?
const convertFilters = (values) => ({
  with_email: values.people.map((p) => p.value),
  with_course_type: values.courseTypes,
  with_organization_type: values.orgType,
  with_region: values.region.toLowerCase(),
  with_mark_training_payment_as: values.markAs.toLowerCase(),
  with_state: values.status.toLowerCase(),
  with_health_authority_and_children: values.healthAuth,
  with_notes_containing: values.notes,
  with_created_date_on_or_after: values.date.startDate,
  with_created_date_on_or_before: values.date.endDate,
});

function* getFilteredVouchers() {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters) });
    yield put(GET_VOUCHERS.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_VOUCHERS);
  }
}

function* getFilteredVouchersPages(action) {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters), page: action.payload });
    yield put(GET_VOUCHER_PAGES.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_VOUCHER_PAGES);
  }
}

function* getVouchersFormData() {
  try {
    const response = yield fetchGet('/api/registrar/vouchers/form_data.json');
    yield put(GET_FORM_DATA.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_FORM_DATA);
  }
}

function* sortVouchersFormData() {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters) });
    yield put(SORT_VOUCHERS.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, SORT_VOUCHERS);
  }
}

function* createVouchers({ payload }) {
  try {
    const { data } = yield fetchPost('/api/registrar/vouchers', payload);
    yield delay(800);
    yield put(CREATE_VOUCHERS.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Vouchers created successfully.',
      type: 'success',
    }));
    yield put(RESET_VOUCHERS.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_VOUCHERS);
  }
}

export default function* sagas() {
  yield takeLatest(GET_VOUCHERS.REQUEST, getFilteredVouchers);
  yield takeLatest(GET_VOUCHER_PAGES.REQUEST, getFilteredVouchersPages);
  yield takeLatest(GET_FORM_DATA.REQUEST, getVouchersFormData);
  yield takeLatest(SORT_VOUCHERS.REQUEST, sortVouchersFormData);
  yield takeLatest(CREATE_VOUCHERS.REQUEST, createVouchers);
}
