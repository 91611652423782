import _merge from 'lodash/merge';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { camelCaseObjectOrArray } from 'lib/utils';
import { GET_GROUP, ASSIGN_COURSE, EXTEND_GROUP, UPDATE_GROUP_SETTINGS, EDIT_GROUP, GET_LIVE_FEED, GET_GROUP_PARTICIPATIONS, LOAD_RESERVATIONS, RESET_GROUP_PARTICIPATIONS, UPDATE_RESERVATIONS, CREATE_PARTICIPANT, RESET_GROUP_STATUS, UPDATE_GROUP_SHOW_DATA, UPDATE_REVIEW, GET_JOURNALS } from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  assigningCourse: false,
  extendingGroup: false,
  extendGroupErrorMessage: null,
  reservations: {
    status: REDUX_STATUS.IDLE,
    allIds: [],
    byId: {},
    error: undefined,
  },
  journals: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case GET_GROUP.SUCCESS:
      return {
        ...state,
        groupMemberships: action.payload.groupMemberships,
        group: Object.values(action.payload.groups)[0],
        subGroups: Object.values(action.payload.subGroups),
        discussionsSections: Object.values(action.payload.discussionsSections),
        facilitatorIds: action.payload.facilitatorIds,
        status: REDUX_STATUS.SUCCESS,
      };
    case GET_GROUP.ERROR:
      return { ...state, status: REDUX_STATUS.ERROR };
    case GET_LIVE_FEED.REQUEST:
      return {
        ...state,
        notificationsLoading: true,
      };
    case GET_LIVE_FEED.SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications,
        notificationsLoading: false,
      };
    case GET_LIVE_FEED.ERROR:
      return { ...state, notificationsLoading: false };

    case GET_JOURNALS.REQUEST:
      return {
        ...state,
        journals: {
          ...state.journals,
          error: undefined,
        },
      };
    case GET_JOURNALS.SUCCESS: {
      if (action.meta.cached) return state;

      return {
        ...state,
        journals: {
          ...state.journals,
          data: action.payload,
          cache: new Date().valueOf(),
        },
      };
    }
    case GET_JOURNALS.ERROR:
      return {
        ...state,
        journals: {
          ...state.journals,
          error: action.payload,
        },
      };

    case ASSIGN_COURSE.REQUEST:
      return { ...state, assigningCourse: true };
    case ASSIGN_COURSE.SUCCESS:
      return {
        ...state,
        group: Object.values(action.payload.groups)[0],
        assigningCourse: false,
      };
    case EDIT_GROUP.SYNC:
      return {
        ...state,
        isSaved: false,
      };

    case UPDATE_GROUP_SETTINGS.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        success: undefined,
        error: undefined,
      };
    case UPDATE_GROUP_SETTINGS.SUCCESS:
      return {
        ...state,
        group: Object.values(action.payload.groups)[0],
        status: REDUX_STATUS.SUCCESS,
        success: REDUX_SUCCESS.SAVED,
      };
    case UPDATE_GROUP_SETTINGS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case UPDATE_REVIEW.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case UPDATE_REVIEW.SUCCESS:
      return {
        ...state,
        group: Object.values(action.payload.groups)[0],
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_REVIEW.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case EXTEND_GROUP.REQUEST:
      return { ...state, extendingGroup: true, extendGroupErrorMessage: null };
    case EXTEND_GROUP.SUCCESS:
      return {
        ...state,
        group: Object.values(action.payload.groups)[0],
        groupMemberships: _merge(state.groupMemberships, action.payload.groupMemberships),
        extendingGroup: false,
      };
    case EXTEND_GROUP.ERROR:
      return { ...state, extendingGroup: false, extendGroupErrorMessage: action.payload.message };

    case LOAD_RESERVATIONS.REQUEST:
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_RESERVATIONS.SUCCESS:
      return {
        ...state,
        reservations: {
          ...state.reservations,
          allIds: action.payload.reservationIds,
          byId: action.payload.reservations,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    case LOAD_RESERVATIONS.ERROR:
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    // Don't need to update state for this action, for now anyway
    // case UPDATE_RESERVATIONS.REQUEST:
    //   return {
    //     ...state,
    //     reservations: {
    //       ...state.reservations,
    //       status: REDUX_STATUS.PENDING,
    //     },
    //   };
    case UPDATE_RESERVATIONS.SUCCESS:
      return {
        ...state,
        reservations: {
          ...state.reservations,
          byId: { ...action.payload.reservations },
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
          message: 'Reservations updated successfully.',
        },
      };
    case UPDATE_RESERVATIONS.ERROR:
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case GET_GROUP_PARTICIPATIONS.REQUEST:
      return state;
    case GET_GROUP_PARTICIPATIONS.SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          countableParticipants: action.payload.length,
          groupParticipations: action.payload,
        },
      };
    case GET_GROUP_PARTICIPATIONS.ERROR:
      return state;

    case CREATE_PARTICIPANT.REQUEST:
      return {
        ...state,
        group: {
          ...state.group,
          status: REDUX_STATUS.PENDING,
          success: undefined,
          error: undefined,
        },
      };
    case CREATE_PARTICIPANT.SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.CREATED,
          groupParticipations: [...state.group.groupParticipations, action.payload],
        },
      };
    case CREATE_PARTICIPANT.ERROR:
      return {
        ...state,
        group: {
          ...state.group,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_GROUP_STATUS.SYNC:
      return {
        ...state,
        group: {
          ...state.group,
          status: REDUX_STATUS.IDLE,
          success: undefined,
          error: undefined,
        },
      };

    case RESET_GROUP_PARTICIPATIONS.SYNC:
      return {
        ...state,
        group: {
          ...state.group,
          groupParticipations: null,
        },
      };

    case UPDATE_GROUP_SHOW_DATA.SYNC: {
      if (action.payload.id !== state.group.id) return state;

      let { countableParticipants, groupParticipations } = state.group;

      const incomingIds = action.payload.participations?.map((gm) => gm.id) || [];
      const hasIncomingId = groupParticipations.some((gm) => incomingIds.includes(gm.id));

      if (!hasIncomingId) {
        countableParticipants += action.payload.participations?.length || 0;
        groupParticipations = [...groupParticipations, ...camelCaseObjectOrArray(action.payload.participations || [])];
      }

      return {
        ...state,
        group: {
          ...state.group,
          ...camelCaseObjectOrArray(action.payload.group),
          status: REDUX_STATUS.IDLE,
          countableParticipants,
          groupParticipations,
        },
      };
    }

    default:
      return state;
  }
};
