import { CREATE_REPORT, FETCH_ALL_REPORTS } from './actions';

const initialState = {
  loading: true,
  reports: {
    allIds: [],
    byId: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: [...action.payload.reportIds, ...state.reports.allIds],
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
      };
    case CREATE_REPORT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_REPORTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: [...action.payload.reportIds],
          byId: { ...action.payload.reports },
        },
      };
    case FETCH_ALL_REPORTS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
