import { fetchGet, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
  CREATE_REPLY, GET_CONVERSATION, GET_MESSAGES, GET_SUB_GROUPS, MARK_CONVERSATION_AS_READ, SEND_MESSAGES, UPDATE_SUBJECT,
} from 'store/messages/actions';

export default function* sagas() {
  yield takeLatest(GET_MESSAGES.REQUEST, function* getMessages({ payload: { page } }) {
    try {
      const result = yield fetchGet('/api/facilitator/messages/messages_index', { page: (page || 1), perPage: 25 });
      yield put(GET_MESSAGES.success(result));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_MESSAGES);
    }
  });

  yield takeLatest(GET_CONVERSATION.REQUEST, function* getConversation({ payload: { conversationId } }) {
    try {
      const result = yield fetchGet(`/api/facilitator/messages/${conversationId}`, {});
      yield put(GET_CONVERSATION.success(result));
      const state = yield select();
      const hasUnreadMessages = state.messages.show.conversation.messages.find((m) => !m.readAt);
      if (hasUnreadMessages) {
        yield put(MARK_CONVERSATION_AS_READ.request({ conversationId }));
      }
    } catch (err) {
      yield handleSimpleFetchError(err, GET_CONVERSATION);
    }
  });

  yield takeLatest(MARK_CONVERSATION_AS_READ.REQUEST, function* markConversationAsRead({ payload: { conversationId } }) {
    try {
      const { messageIds } = yield fetchPost(`/api/facilitator/messages/${conversationId}/mark_as_read`, {});
      yield put(MARK_CONVERSATION_AS_READ.success({ conversationId, messageIds }));
      // Update the navbar total message count manually
      const el = document.getElementById('total-unread-messages');
      const totalUnreadCount = Number(el.innerText);
      el.innerText = totalUnreadCount - messageIds.length;
    } catch (err) {
      yield handleSimpleFetchError(err, MARK_CONVERSATION_AS_READ);
    }
  });

  yield takeLatest(GET_SUB_GROUPS.REQUEST, function* getSubGroups() {
    try {
      const result = yield fetchGet('/api/facilitator/messages/sub_groups_for_create', {});
      yield put(GET_SUB_GROUPS.success(result));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_SUB_GROUPS);
    }
  });

  yield takeLatest(SEND_MESSAGES.REQUEST, function* sendMessages({ payload: { subject, message } }) {
    try {
      const { messages: { compose: { selectedRecipients, preSelectedRecipient } } } = yield select();
      const result = yield fetchPost(
        '/api/facilitator/messages',
        {
          recipients: (selectedRecipients || { groupMembershipIds: [preSelectedRecipient.id] }),
          messageSubject: subject,
          messageBody: message,
        },
      );
      yield put(SEND_MESSAGES.success(result));
      // This is not the most elegant way to do this, but it's fast and we need fast now.
      // Redirect the user to the messages page, which reloads all messages
      window.location = '/facilitator/messages';
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('The message could not be sent. Please ensure all fields are filled in and try again.');
      yield handleSimpleFetchError(err, SEND_MESSAGES);
    }
  });

  yield takeLatest(CREATE_REPLY.REQUEST, function* createReply({ payload: { message } }) {
    const { messages: { show: { conversation } } } = yield select();
    try {
      const result = yield fetchPost(`/api/facilitator/messages/${conversation.id}/reply`, { messageBody: message });
      yield put(CREATE_REPLY.success(result));
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_REPLY);
    }
  });

  yield takeLatest(UPDATE_SUBJECT.REQUEST, function* updateSubject() {
    const { messages: { show: { newSubject, conversation } } } = yield select();
    try {
      yield fetchPost(
        `/api/facilitator/messages/${conversation.id}/update_subject`,
        { newSubject },
      );
      yield put(UPDATE_SUBJECT.success());
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Could not update the subject. Please reload the page and try again.');
      yield handleSimpleFetchError(err, UPDATE_SUBJECT);
    }
  });
}
