import { REDUX_STATUS } from 'lib/constants';
import { CREATE_VOUCHERS, GET_FORM_DATA, GET_VOUCHER_PAGES, GET_VOUCHERS, RESET_VOUCHERS, SORT_VOUCHERS } from './actions';

const initialState = {
  loading: true,
  submitting: false,
  searchResults: {},
  voucherIds: [],
  currentPage: 1,
  totalPages: 1,
  perPage: 1,
  formData: undefined,
  vouchers: {
    status: REDUX_STATUS.IDLE,
    data: undefined,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FORM_DATA.REQUEST:
      return {
        ...state,
        submitting: true,
        loading: true,
      };
    case GET_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
        submitting: false,
        loading: false,
      };
    case GET_FORM_DATA.FAILURE:
      return {
        ...state,
        submitting: false,
        loading: false,
      };
    case SORT_VOUCHERS.REQUEST:
      return {
        ...state,
        submitting: true,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case GET_VOUCHER_PAGES.REQUEST:
      return {
        ...state,
        submitting: true,
        page: action.payload,
      };
    case GET_VOUCHERS.REQUEST:
      return {
        ...state,
        submitting: true,
        filters: action.payload,
      };
    case GET_VOUCHER_PAGES.SUCCESS:
    case SORT_VOUCHERS.SUCCESS:
    case GET_VOUCHERS.SUCCESS:
      return {
        ...state,
        searchResults: action.payload.vouchers,
        voucherIds: action.payload.voucherIds,
        currentPage: action.payload.currentPage,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
        submitting: false,
        loading: false,
      };
    case GET_VOUCHER_PAGES.FAILURE:
    case SORT_VOUCHERS.FAILURE:
    case GET_VOUCHERS.FAILURE:
      return {
        ...state,
        submitting: false,
        loading: false,
      };

    case CREATE_VOUCHERS.REQUEST:
      return {
        ...state,
        vouchers: {
          status: REDUX_STATUS.PENDING,
          data: action.payload,
          error: undefined,
        },
      };
    case CREATE_VOUCHERS.SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          status: REDUX_STATUS.SUCCESS,
          data: action.payload,
        },
      };
    case CREATE_VOUCHERS.ERROR:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case RESET_VOUCHERS.REQUEST:
      return {
        ...state,
        vouchers: {
          status: REDUX_STATUS.IDLE,
          data: undefined,
          error: undefined,
        },
      };

    default:
      return state;
  }
};
