import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { LEGACY_APP_DOMAIN } from 'config';
import { CREATE_GROUPS } from 'store/groupCreate/actions';
import { LOAD_COURSE_TYPES } from 'store/courseTypes/actions';
import Spinner from 'components/shared/Spinner';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Form from 'components/registrar/Form';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import { REDUX_STATUS } from 'lib/constants';

function GroupCreatePage() {
  const { formState, groupsCreated } = useSelector((state) => state.groupCreate);
  const organizations = useSelector((state) => state.organizations);
  const loading = organizations.status === REDUX_STATUS.PENDING;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPES.request());
  }, [dispatch]);

  const handleSubmit = useCallback((dataForGroups) => {
    dispatch(CREATE_GROUPS.request(dataForGroups));
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Groups', href: '/registrar/groups' },
                { label: 'Create Groups' },
              ]}
            />

            <h1 className="mb-0">Create Groups</h1>
          </div>
        </div>
      </div>

      <div className="flush px-4 my-4">
        <p>
          After the groups are created, you can set up
          {' '}
          <a href={`${LEGACY_APP_DOMAIN}/registrar/reservations_matrix/edit`} target="_blank" rel="noreferrer">seat reservations</a>
          {' '}
          and
          {' '}
          <a href={`${LEGACY_APP_DOMAIN}/facilitators/planning_matrix`} target="_blank" rel="noreferrer">sub-group facilitations</a>
          .
        </p>

        {loading && <Spinner message="Loading..." />}

        {!loading && (
          <>
            { formState === 'error' && <Alert variant="danger">The groups could not be created.</Alert> }
            { formState === 'created' && (
              <Alert variant="success">
                Successfully created
                {' '}
                {groupsCreated.length}
                {' '}
                group(s).
              </Alert>
            ) }

            <Form
              organizations={organizations}
              creating={formState === 'creating'}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </div>
    </RegistrarTemplate>
  );
}

export default GroupCreatePage;
