import { REDUX_STATUS } from 'lib/constants';
import { CREATE_PLATFORM_CLOSURE, UPDATE_PLATFORM_CLOSURE, GET_PLATFORM_CLOSURE_STATUS, LOAD_PLATFORM_CLOSURES, RESET_EVENT_STATUS, LOAD_VALIDATION_GROUPS, RESET_VALIDATION_GROUPS } from './actions';

const initialState = {
  status: {
    status: REDUX_STATUS.IDLE,
    data: undefined,
  },
  events: {
    status: REDUX_STATUS.IDLE,
    allIds: [],
    byId: {},
    error: undefined,
    message: null,
  },
  event: {
    status: REDUX_STATUS.IDLE,
    id: undefined,
    data: {},
    error: undefined,
    message: null,
  },
  validation: {
    status: REDUX_STATUS.IDLE,
    groups: {},
    groupIds: [],
    dateRange: {},
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PLATFORM_CLOSURE.REQUEST:
      return {
        ...state,
        event: {
          status: REDUX_STATUS.PENDING,
          id: undefined,
          data: action.payload,
          error: undefined,
          message: null,
        },
      };
    case CREATE_PLATFORM_CLOSURE.SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          allIds: [...state.events.allIds, action.payload.id],
          byId: { ...state.events.byId, [action.payload.id]: action.payload },
        },
        event: {
          ...state.event,
          status: REDUX_STATUS.SUCCESS,
          id: action.payload.id,
          data: action.payload,
          message: 'Platform closure scheduled successfully.',
        },
      };
    case CREATE_PLATFORM_CLOSURE.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case UPDATE_PLATFORM_CLOSURE.REQUEST:
      return {
        ...state,
        event: {
          status: REDUX_STATUS.PENDING,
          id: action.payload.id,
          data: action.payload,
          error: undefined,
          message: null,
        },
      };
    case UPDATE_PLATFORM_CLOSURE.SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          byId: { ...state.events.byId, [action.payload.id]: action.payload },
          status: REDUX_STATUS.SUCCESS,
        },
        event: {
          ...state.event,
          status: REDUX_STATUS.SUCCESS,
          id: action.payload.id,
          data: action.payload,
          message: 'Platform closure updated successfully.',
        },
      };
    case UPDATE_PLATFORM_CLOSURE.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case GET_PLATFORM_CLOSURE_STATUS.REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          status: REDUX_STATUS.PENDING,
        },
      };
    case GET_PLATFORM_CLOSURE_STATUS.SUCCESS:
      return {
        ...state,
        status: {
          ...action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case GET_PLATFORM_CLOSURE_STATUS.ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };
    case LOAD_PLATFORM_CLOSURES.REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          status: REDUX_STATUS.PENDING,
          error: undefined,
          message: null,
        },
      };
    case LOAD_PLATFORM_CLOSURES.SUCCESS: {
      if (action.meta.cached) return { ...state, events: { ...state.events, status: REDUX_STATUS.SUCCESS } };

      return {
        ...state,
        events: {
          ...state.events,
          allIds: action.payload.eventIds,
          byId: action.payload.events,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    }
    case LOAD_PLATFORM_CLOSURES.ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case LOAD_VALIDATION_GROUPS.REQUEST:
      return {
        ...state,
        validation: {
          ...state.validation,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_VALIDATION_GROUPS.SUCCESS: {
      if (action.meta.cached) return { ...state, validation: { ...state.validation, status: REDUX_STATUS.SUCCESS } };

      return {
        ...state,
        validation: {
          ...state.validation,
          groups: action.payload.groups,
          groupIds: action.payload.groupIds,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_VALIDATION_GROUPS.ERROR:
      return {
        ...state,
        validation: {
          ...state.validation,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_VALIDATION_GROUPS.SYNC:
      return {
        ...state,
        validation: {
          ...state.validation,
          groups: {},
          groupIds: [],
          status: REDUX_STATUS.IDLE,
          error: undefined,
        },
      };

    case RESET_EVENT_STATUS.SYNC:
      return {
        ...state,
        events: {
          ...state.events,
          status: REDUX_STATUS.IDLE,
          error: undefined,
          message: null,
        },
        event: {
          ...state.event,
          status: REDUX_STATUS.IDLE,
          error: undefined,
          message: null,
        },
      };
    default:
      return state;
  }
};
