import { all, fork } from 'redux-saga/effects';

import root from './sagas';
import courseTypes from './courseTypes/sagas';
import courses from './courses/sagas';
import emailTemplates from './emailTemplates/sagas';
import emailTransmissionReport from './emailTransmissionReport/sagas';
import facilitator from './facilitator/sagas';
import facilitatorDashboard from './facilitatorDashboard/sagas';
import facilitatorReport from './facilitatorReport/sagas';
import flashNotifications from './flashNotifications/sagas';
import groupCreate from './groupCreate/sagas';
import groupMemberships from './groupMemberships/sagas';
import groupShow from './groupShow/sagas';
import harmPreventionReport from './harmPreventionReport/sagas';
import healthAuthorities from './healthAuthorities/sagas';
import indigenousWelcomeLetters from './indigenousWelcomeLetters/sagas';
import messages from './messages/sagas';
import organizations from './organizations/sagas';
import participantShow from './participantShow/sagas';
import paymentReport from './paymentReport/sagas';
import platformClosure from './platformClosure/sagas';
import ptqReport from './ptqReport/sagas';
import registrar from './registrar/sagas';
import responsesReport from './responsesReport/sagas';
import superFacilitatorReport from './superFacilitatorReport/sagas';
import user from './user/sagas';
import vouchers from './vouchers/sagas';

const sagas = [
  root,
  courseTypes,
  courses,
  emailTemplates,
  emailTransmissionReport,
  facilitator,
  facilitatorDashboard,
  facilitatorReport,
  flashNotifications,
  groupCreate,
  groupMemberships,
  groupShow,
  harmPreventionReport,
  healthAuthorities,
  indigenousWelcomeLetters,
  messages,
  organizations,
  participantShow,
  paymentReport,
  platformClosure,
  ptqReport,
  registrar,
  responsesReport,
  superFacilitatorReport,
  user,
  vouchers,
];

export default function* main(services = {}) {
  yield all(
    [
      ...sagas.map((saga) => fork(saga, services)),
    ],
  );
}
