import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-props-no-spreading */

function TextareaField({ field, form, ...props }) {
  const { name, value, onChange } = field;
  const ref = useRef(null);

  const autoResize = useCallback(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight + 2}px`; // +2px to account for border
    }
  }, [ref]);

  const handleChange = useCallback((e) => {
    autoResize();
    onChange(e);
  }, [autoResize, onChange]);

  useEffect(() => {
    autoResize();
  }, [autoResize]);

  return (
    <textarea ref={ref} name={name} value={value} onChange={handleChange} {...props} />
  );
}

TextareaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    // https://formik.org/docs/api/field
  }).isRequired,
};

export default TextareaField;
