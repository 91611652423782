import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { formatISO, isPast, isValid, parseISO, startOfToday } from 'date-fns';
import { REDUX_SUCCESS } from 'lib/constants';
import { GroupPropType } from 'lib/propTypes';
import { UPDATE_GROUP_SETTINGS } from 'store/groupShow/actions';
import { Button, Spinner as RBSpinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import AlertDismissible from 'components/shared/AlertDismissible';
import SelectField from 'components/shared/FormFields/SelectField';
import InputField from 'components/shared/FormFields/InputField';
import DateTimePicker from 'components/shared/DateTimePicker';

function dateIsPast(dateString) {
  return isPast(parseISO(dateString));
}

function validateSequence(field, value, context) {
  const fieldValue = context.parent[field];
  if (value) {
    return parseISO(value) > parseISO(fieldValue);
  }
  return true;
}

function validateDate(value) {
  return isValid(parseISO(value));
}

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  maxParticipants: Yup.number(),
  expireReservationsAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Expire cannot be in the past',
      (value, context) => {
        if (value !== context.originalValue) {
          return !dateIsPast(value);
        }
        return true;
      },
    ),
  closeRegistrationAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Close must be after Expire',
      (value, context) => validateSequence('expireReservationsAt', value, context),
    ),
  sendParticipantListsAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Send lists must be after Close',
      (value, context) => validateSequence('closeRegistrationAt', value, context),
    ),
  sendWelcomePacksAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Send welcome must be after Send lists',
      (value, context) => validateSequence('sendParticipantListsAt', value, context),
    ),
  beginSessionAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Begin must be after Send welcome',
      (value, context) => validateSequence('sendWelcomePacksAt', value, context),
    ),
  closeSessionAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Close must be after Begin',
      (value, context) => validateSequence('beginSessionAt', value, context),
    ),
  certificateAvailableAt: Yup.string().required('Required').typeError('Invalid date')
    .test('valid-date', 'Invalid date', (value) => validateDate(value))
    .test(
      'date-sequence',
      'Certificate must be after Close',
      (value, context) => validateSequence('closeSessionAt', value, context),
    ),
});

function GroupSettingsForm({ group }) {
  const dispatch = useDispatch();
  const { success: groupSuccess, error: groupError } = useSelector((state) => state.groupShow);

  const initialValues = {
    id: group.id,
    name: group.name,
    languagePreference: group.languagePreference ?? 'en',
    maxParticipants: group.maxParticipants ?? 0,
    expireReservationsAt: group.expireReservationsAt,
    closeRegistrationAt: group.closeRegistrationAt,
    sendParticipantListsAt: group.sendParticipantListsAt,
    sendWelcomePacksAt: group.sendWelcomePacksAt,
    beginSessionAt: group.beginSessionAt,
    closeSessionAt: group.closeSessionAt,
    certificateAvailableAt: group.certificateAvailableAt,
  };

  const handleSubmit = useCallback((values, actions) => {
    dispatch(UPDATE_GROUP_SETTINGS.request(values, { formikActions: actions }));
  }, [dispatch]);

  return (
    <>
      <ErrorMessage error={groupError} className="mt-2" />
      {groupSuccess === REDUX_SUCCESS.SAVED && (
        <AlertDismissible className="mt-3">
          Group saved successfully.
        </AlertDismissible>
      )}

      <Formik
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, dirty, isSubmitting }) => (
          <Form>
            <div className="d-flex align-items-center">
              <h2 className="mb-0">Basic Information</h2>
              <Button type="submit" className="ms-auto" disabled={!dirty || isSubmitting}>
                Save
                {isSubmitting && <RBSpinner size="sm" className="ms-1" animation="border" role="status" />}
              </Button>
            </div>

            <fieldset className="mb-4">
              <InputField label="Group name" name="name" />

              <div className="row">
                <SelectField
                  label="Preferred language"
                  options={[{ value: 'en', label: 'English' }, { value: 'fr', label: 'French' }]}
                  name="languagePreference"
                  className="col-md-6 col-xl-4"
                  disabled={!group.isAvailableInFrench}
                  value={group.isAvailableInFrench ? values.languagePreference : 'en'}
                  helpText="Default all notifications to this language unless the participant has a preferred language set already."
                  infoText={!group.isAvailableInFrench ? 'This option is only available to change if the assigned course is available in French.' : null}
                />

                <InputField className="col-md-6 col-xl-4" name="maxParticipants" type="number" min="0" />
              </div>
            </fieldset>

            <fieldset>
              <h2 className="mb-0">Key Dates</h2>
              <div className="mb-3">
                <label className="form-label">Expire Reservations</label>
                <Field
                  name="expireReservationsAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.expireReservationsAt)}
                  min={dateIsPast(group.expireReservationsAt) ? group.expireReservationsAt : formatISO(startOfToday())}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Close Registration</label>
                <Field
                  name="closeRegistrationAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.closeRegistrationAt)}
                  min={values.expireReservationsAt}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Send Participant Lists</label>
                <Field
                  name="sendParticipantListsAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.sendParticipantListsAt)}
                  min={values.closeRegistrationAt}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Send Welcome Packs</label>
                <Field
                  name="sendWelcomePacksAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.sendWelcomePacksAt)}
                  min={values.sendParticipantListsAt}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Begin Session</label>
                <Field
                  name="beginSessionAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.beginSessionAt)}
                  min={values.sendWelcomePacksAt}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Close Session</label>
                <Field
                  name="closeSessionAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.closeSessionAt)}
                  min={values.beginSessionAt}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Certificate Available</label>
                <Field
                  name="certificateAvailableAt"
                  component={DateTimePicker}
                  disabled={dateIsPast(group.certificateAvailableAt)}
                  min={values.closeSessionAt}
                />
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>
    </>
  );
}

GroupSettingsForm.propTypes = {
  group: GroupPropType.isRequired,
};

export default GroupSettingsForm;
