import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CREATE_COVERAGE_REPORT, GET_COVERAGE_FORM_DATA } from 'store/superFacilitatorReport/actions';
import Button from 'react-bootstrap/Button';
import SelectField from 'components/shared/FormFields/SelectField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';
import Skeleton from '../FacilitatorReports/FormSkeleton';

const FormSchema = Yup.object().shape({
  reportType: Yup.string().required('Required'),
  totalUnfacilitatedPosts: Yup.number()
    .required('Please indicate number of unfacilitated posts')
    .typeError('Please indicate number of unfacilitated posts'),
});

function RequestForm() {
  const dispatch = useDispatch();
  const { formLoading, formData } = useSelector((state) => state.superFacilitatorReport);

  useEffect(() => {
    dispatch(GET_COVERAGE_FORM_DATA.request());
  }, [dispatch]);

  const handleSubmit = useCallback((values, { resetForm }) => {
    dispatch(CREATE_COVERAGE_REPORT.request(values));
    resetForm();
  }, [dispatch]);

  if (formLoading) return <Skeleton />;

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      initialValues={{
        reportType: 'coverage',
        facilitatorIds: [],
        courseTypes: [],
        discussionBoards: [],
        totalUnfacilitatedPosts: '',
      }}
    >
      {({ resetForm }) => (
        <Form>
          <div className="row">
            <div className="col-md-8 col-xl-6">
              <SelectField
                name="reportType"
                label="Report Type"
                options={[
                  { value: 'stats', label: 'Coverage' },
                ]}
                className="d-none"
              />

              <div className="row">
                <div className="col-md-6">
                  <DatePickerField
                    name="startDate"
                    label="Report Timeframe"
                    placeholder="Pick start date (optional)"
                    dateFormat="m/d/Y"
                    infoText={(
                      <p className="mb-0 text-start">
                        Results for before
                        {' '}
                        <span className="fw-semibold">March 15, 2021</span>
                        {' '}
                        do not take into account facilitators providing coverage for each other.
                        Responses before this date will be credited to the facilitator being covered.
                      </p>
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <DatePickerField
                    name="endDate"
                    label="to:"
                    placeholder="Pick end date (optional)"
                    dateFormat="m/d/Y"
                  />
                </div>
              </div>

              <SelectField
                name="facilitatorIds"
                label="Facilitators"
                placeholder="Filter by facilitator (optional)"
                options={formData.facilitators}
                multiple
              />

              <SelectField
                name="courseTypes"
                label="Courses"
                placeholder="Filter by course (optional)"
                options={formData.courseTypes}
                multiple
              />

              <SelectField
                name="discussionBoards"
                label="Discussion Boards"
                placeholder="Filter by discussion boards (optional)"
                options={formData.discussionBoards}
                multiple
              />

              <SelectField
                name="totalUnfacilitatedPosts"
                label="Number of unfacilitated posts"
                placeholder="Number of unfacilitated participant posts"
                options={[
                  { value: 3, label: '3 or more' },
                  { value: 5, label: '5 or more' },
                  { value: 10, label: '10 or more' },
                  { value: 15, label: '15 or more' },
                ]}
                required
              />
            </div>
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" className="me-2">
              Request this report
            </Button>

            <Button variant={null} className="btn-plain" onClick={resetForm}>
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RequestForm;
