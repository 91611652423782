import { GET_CURRENT_USER } from 'store/actions';
import { LOAD_HEALTH_AUTHORITIES } from './healthAuthorities/actions';
import { LOAD_ORGANIZATIONS } from './organizations/actions';
import { GET_PLATFORM_CLOSURE_STATUS } from './platformClosure/actions';

export default function initialize(store) {
  store.dispatch(GET_CURRENT_USER.request());
  store.dispatch(GET_PLATFORM_CLOSURE_STATUS.request());
  store.dispatch(LOAD_ORGANIZATIONS.request());
  store.dispatch(LOAD_HEALTH_AUTHORITIES.request());
}
