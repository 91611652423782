import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FETCH_ALL_FACILITATOR_REPORTS, GET_FACILITATOR_RPT_FORM_DATA } from 'store/facilitatorReport/actions';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SuperFacilitatorTemplate from 'components/templates/SuperFacilitatorTemplate';
import FacilitatorReportsList from 'components/reporting/FacilitatorReports/FacilitatorReportsList';
import RequestForm from 'components/reporting/FacilitatorReports/RequestForm';

function FacilitatorReport() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FETCH_ALL_FACILITATOR_REPORTS.request());
    dispatch(GET_FACILITATOR_RPT_FORM_DATA.request());

    const id = setInterval(() => {
      dispatch(FETCH_ALL_FACILITATOR_REPORTS.request());
    }, 15 * 1000);

    return () => clearInterval(id);
  }, [dispatch]);

  return (
    <SuperFacilitatorTemplate>
      <div className="header flush">

        <Breadcrumbs
          navItems={
                [
                  { label: 'Reports', href: '/lead_facilitator/reports' },
                  { label: 'Facilitator Journal' },
                ]
              }
        />

        <h1 className="mb-0">Facilitator Journal Report</h1>
      </div>

      <div className="flush px-4 my-4">
        <p className="lead">
          This report provides statistics about facilitator journal responses.
        </p>

        <p>
          Reports are generated in the background. Once a report is
          requested, this page can be closed and the report will continue
          generating. This page is auto-updating and will display reports as
          they are in-progress and eventually completed. Completed reports
          can be downloaded in comma-separated value (CSV) format.
        </p>

        <RequestForm />

        <FacilitatorReportsList />
      </div>
    </SuperFacilitatorTemplate>
  );
}

export default FacilitatorReport;
